<template>
    <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols=12 >
                    <label>{{ fieldLabel  }}</label>
                    <v-file-input outlined v-model="inputFile" />
                    <v-btn @click="submit">Submit</v-btn>
                </v-col>
                <v-col cols=12>
                    <request :request="request" @on-success="downloadCsv" @on-error="printError">
                        <v-btn
                            color="primary"
                            :href="result"
                            :download="downloadLabel">
                            <v-icon small left>$download</v-icon>Download Results
                        </v-btn>
                    </request>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import webapi from '@/core/webapi';
    import CONSTANTS from '@/constants';

    export default {
        name: 'ModelList',

        props: {
            title: {
                type: String,
                required: true,
            },
            fieldLabel: {
                type: String,
                required: true,
            },
            requestUri: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            inputFile: undefined,
            request: undefined,
            result: undefined,
        }),

        computed: {
            downloadLabel() {
                return this.title.replaceAll(' ', '_');
            },
        },

        methods: {
            navigateToModel(modelKey) {
                this.selectedModel = console;
                modelKey.log('Model_KEY', modelKey);
            },
            submit() {
                const uri = `${CONSTANTS.API_BASE}${this.requestUri}`;
                const body = new FormData();
                body.append('file', this.inputFile);
                this.request = () => webapi.upload({ uri, body });
            },
            downloadCsv(response) {
                console.log('got response', response);
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                this.result = URL.createObjectURL(blob);
            },
            printError(err) {
                console.error(err);
            },
        },
    };
</script>
