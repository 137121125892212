<template>
    <v-row>
        <v-col cols=12>
            <h2>Unbound Compute Lead</h2>
            <label>File with leads</label>
            <v-file-input outlined v-model="inputFile" />
            <v-btn @click="submit">Submit</v-btn>
        </v-col>
        <v-col cols=12>
            <request :request="request" @on-success="downloadCsv" @on-error="printError">
                <v-btn
                    color="primary"
                    :href="result"
                    :download="downloadLabel">
                    <v-icon small left>$download</v-icon>Download Results
                </v-btn>
            </request>
        </v-col>
    </v-row>
</template>

<script>
    import webapi from '@/core/webapi';
    import CONSTANTS from '@/constants';

    const MODEL_URI = `${CONSTANTS.API_BASE}/gateway/compute/unbound-leads`;
    export default {
        name: 'ModelList',

        data: () => ({
            inputFile: undefined,
            request: undefined,
            result: undefined,
            downloadLabel: 'model-result',
        }),

        methods: {
            navigateToModel(modelKey) {
                this.selectedModel = console;
                modelKey.log('Model_KEY', modelKey);
            },
            submit() {
                const uri = MODEL_URI;
                const body = new FormData();
                body.append('file', this.inputFile);
                this.request = () => webapi.upload({ uri, body });
            },
            downloadCsv(response) {
                console.log('got response', response);
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                // this.result = response.data;
                link.href = URL.createObjectURL(blob);
                this.result = URL.createObjectURL(blob);
                // link.download = 'model-result';
                // link.click();
                // URL.revokeObjectURL(link.href);
            },
            printError(err) {
                console.error(err);
            },
        },
    };
</script>
