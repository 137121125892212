<template>
    <request :request="initRequest" @on-success="setModels" @on-error="printError">
        <v-row>
            <v-col cols=3>
                <v-card>
                    <v-card-title>Models</v-card-title>
                    <v-simple-table class="text-left">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    @click="navigateToModel(i)"
                                    v-for="item, i in models"
                                    :key="item.id"
                                >
                                    <td>
                                        <b>{{ item.name }}</b>
                                        <div>
                                            <small>
                                                {{ item.description }}
                                            </small>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols=9>
                <component v-if="selectedModel"
                           v-bind:is="selectedModel.component"
                           v-bind="selectedModel.props" />
            </v-col>
        </v-row>
    </request>
</template>

<script>
    import webapi from '@/core/webapi';
    import CONSTANTS from '@/constants';
    import UnboundLead from '@/apps/manage/pages/ModelList/UnboundLead.vue';
    import UploadDownload from '@/apps/manage/pages/ModelList/UploadDownload.vue';

    const REGISTRY_URI = `${CONSTANTS.API_BASE}/gateway/compute/registry`;

    export default {
        name: 'ModelList',

        components: {
            UnboundLead,
            UploadDownload,
        },

        data: () => ({
            models: [],
            initRequest: undefined,
            selectedModel: undefined,
        }),

        created() {
            this.initialRequest();
        },

        methods: {
            initialRequest() {
                const uri = REGISTRY_URI;
                this.initRequest = () => webapi.get({ uri });
            },
            navigateToModel(modelIdx) {
                this.selectedModel = this.models[modelIdx];
                console.log('Model_Idx', modelIdx);
            },
            setModels(response) {
                console.log('resp', response);
                this.models = response.models;
            },
            printError(err) {
                console.error(err);
            },
        },
    };
</script>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
